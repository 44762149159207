import ThemisNotifications from "@/components/user-settings/notifications"
import { mapGetters, mapActions, mapMutations } from "vuex"
import ThemisSecurity from "@/components/user-settings/security"
import { ANCHOR_LINKS } from "@/constants"
import ThemisTermsOfUse from "@/components/user-settings/terms-of-use"
import moment from "moment"
import { NOTIFICATIONS } from "@/constants"

export default {
  name      : "UserSettings",
  components: {
    ThemisSecurity,
    ThemisNotifications,
    ThemisTermsOfUse
  },
  data() {
    return {
      userSettingsTab  : null,
      isEmailSubscribed: false
    }
  },
  computed: {
    ...mapGetters({
      isDefaultSsoEnabled        : "configurations/isDefaultSsoEnabled",
      emailSubscriptions         : "emailSubscriptions/emailSubscriptions",
      isUpdatingEmailSubscription: "emailSubscriptions/isUpdatingEmailSubscription",
      isEmailSubscriptionUpdated : "emailSubscriptions/isEmailSubscriptionUpdated",
      loggedInUser               : "auth/loggedInUser",
      isChangingPassword         : "auth/isChangingPassword",
      isPasswordChanged          : "auth/isPasswordChanged",
      isInvalidCredentials       : "auth/isInvalidCredentials",
      hasExceededAttempts        : "auth/hasExceededAttempts",
      isMfaEnabledForLoggedInUser: "auth/isMfaEnabledForLoggedInUser",
      secretCode                 : "auth/secretCode",
      isTokenVerified            : "auth/isTokenVerified",
      isVerifyingToken           : "auth/isVerifyingToken",
      isInvalidTotp              : "auth/isInvalidTotp",
      isMfaEnabled               : "auth/isMfaEnabled",
      termsOfUse                 : "configurations/termsOfUse"
    }),
    tabs() {
      return {
        [ANCHOR_LINKS.PASSWORD]     : 0,
        [ANCHOR_LINKS.NOTIFICATIONS]: this.isDefaultSsoEnabled ? 0 : 1,
        [ANCHOR_LINKS.TERMS_OF_USE] : this.isDefaultSsoEnabled ? 1 : 2
      }
    },
    termsOfUseAccepted() {
      return this.loggedInUser.termsOfUseAccepted
    },
    termsOfUseAcceptedAt() {
      return moment(this.loggedInUser.termsOfUseAcceptedAt).format("MMMM DD, YYYY")
    }
  },
  methods: {
    ...mapActions({
      updateEmailSubscription : "emailSubscriptions/updateEmailSubscription",
      notify                  : "shared/notify",
      changePassword          : "auth/changePassword",
      generateSharedSecretCode: "auth/generateSharedSecretCode",
      verifySecretToken       : "auth/verifySecretToken",
      assignUserMfaPreference : "auth/assignUserMfaPreference"
    }),
    ...mapMutations({
      setInvalidCredentials: "auth/setInvalidCredentials",
      setInvalidTotp       : "auth/setInvalidTotp"
    }),
    handleUpdateEmailSubscriptionEvent(payload) {
      for (const emailSubscription of this.emailSubscriptions) {
        if (emailSubscription.notificationId === NOTIFICATIONS.DUE_DATE_REMINDER_DUE_SOON_ASSIGNEE &&
            payload.id === emailSubscription.id) {
          const overdueSubscriptionForAssignee = this.emailSubscriptions.find(subscription =>
            subscription.notificationId === NOTIFICATIONS.DUE_DATE_REMINDER_OVERDUE_ASSIGNEE)
          this.updateEmailSubscription({
            id        : overdueSubscriptionForAssignee.id,
            subscribed: payload.subscribed
          })
        }
        if (emailSubscription.notificationId === NOTIFICATIONS.DUE_DATE_REMINDER_DUE_SOON_EDITING_RIGHTS &&
            payload.id === emailSubscription.id) {
          const overdueSubscriptionForEditingRights = this.emailSubscriptions.find(subscription =>
            subscription.notificationId === NOTIFICATIONS.DUE_DATE_REMINDER_OVERDUE_EDITING_RIGHTS)
          this.updateEmailSubscription({
            id        : overdueSubscriptionForEditingRights.id,
            subscribed: payload.subscribed
          })
        }
      }
      this.updateEmailSubscription(payload)
      this.isEmailSubscribed = payload.subscribed
    },
    handleTabClickEvent(hash) {
      this.userSettingsTab = this.tabs[hash] || this.tabs[ANCHOR_LINKS.PASSWORD]
      if (this.$route.hash !== hash) {
        this.$router.push({ hash })
      }
    }
  },
  watch: {
    isEmailSubscriptionUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          if(this.isEmailSubscribed) {
            this.notify({
              type: "success",
              text: "1183"
            })
          } else {
            this.notify({
              type: "success",
              text: "1182"
            })
          }
        }
      }
    },
    totp: {
      immediate: true,
      handler  : function() {
        if (this.isInvalidTotp) {
          this.setInvalidTotp(false)
        }
      }
    },
    isPasswordChanged: {
      handler: function(newValue) {
        if (newValue) {
          this.showPassword = {
            current: undefined,
            new    : undefined
          }
          this.password     = {
            current: undefined,
            new    : undefined
          }
          this.notify({
            type: "success",
            text: "503"
          })
        }
      }
    },
    hasExceededAttempts: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "error",
            text: "427",
            icon: "mdi-alert-circle"
          })
        }
      }
    },
    isMfaEnabled: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "443"
          })
        } else {
          this.notify({
            type: "success",
            text: "444"
          })
        }
      }
    },
    "$route.hash": {
      immediate: true,
      handler  : function(hash) {
        if(hash) {
          this.handleTabClickEvent(hash)
        }
      }
    }
  }
}